const logoScrollImg = new URL(
	"../assets/images/desktop/header/logoScroll.png",
	import.meta.url
);
const logoImg = new URL("../assets/images/desktop/logo.png", import.meta.url);

const open = (answer) => {
	answer.classList.remove("collapse");
	answer.classList.add("expand");
};

const close = (answer) => {
	answer.classList.remove("expand");
	answer.classList.add("collapse");
};

const pairs = [
	{
		ask: document.getElementById("ask1"),
		answer: document.getElementById("answer1"),
	},
	{
		ask: document.getElementById("ask2"),
		answer: document.getElementById("answer2"),
	},
	{
		ask: document.getElementById("ask3"),
		answer: document.getElementById("answer3"),
	},
	{
		ask: document.getElementById("ask4"),
		answer: document.getElementById("answer4"),
	},
];

for (const pair of pairs) {
	pair.ask.addEventListener("click", () =>
		pair.answer.classList.contains("collapse")
			? open(pair.answer)
			: close(pair.answer)
	);
}

const handleScroll = () => {
	const headerBg = document.getElementById("headerBg");
	const nav = document.querySelector("nav");
	const img = document.getElementById("logo");

	if (window.scrollY !== 0) {
		nav.classList.add("navAnim");
		nav.classList.remove("navOrigin");
		headerBg.classList.add("headerBgAnim");
		headerBg.classList.remove("headerBgOrigin");
		img.setAttribute("srcset", logoScrollImg);
	} else {
		nav.classList.remove("navAnim");
		nav.classList.add("navOrigin");
		headerBg.classList.remove("headerBgAnim");
		headerBg.classList.add("headerBgOrigin");
		img.setAttribute("srcset", logoImg);
	}
};

const handleLoad = () => {
	const loader = document.getElementById("loaderContainer");

	loader.remove();
	window.removeEventListener("load", handleLoad);
	if (window.scrollY !== 0) {
		handleScroll();
	}
};

window.addEventListener("scroll", handleScroll);
window.addEventListener("load", handleLoad);
